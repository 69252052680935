<template>
  <div class="h-full print:w-full">
    <Portal v-if="order.loadingStatus === 'done'" to="title">
      <template v-if="order.humanReadableId">
        {{ $t('order_edit.edit_for') }} {{ order.humanReadableId }}
      </template>
      <template v-else>
        {{ $t('order_edit.edit_order') }}
      </template>
    </Portal>
    <Portal to="header">
      <div class="flex items-center">
        <button :disabled="savingOrder" class="button bg-gray-300 button-text button-rounded-full h-40 w-40 flex items-center justify-center
                  mr-10 laptop:button-lg laptop:button-rounded-base laptop:w-auto laptop:mr-20" @click="saveOrder">
          <IconRefresh :class="{ 'animation-spin': savingOrder }" class="icon-save h-26 w-26 mr-8" />
          {{ $t('order_edit.save_to_draft') }}
        </button>
        <button class="button button-primary button-lg button-filled flex items-center -mt-2" @click="printWindowOpen">
          <IconPrint class="icon-print h-20 w-20 text-gray-900" />
          <span class="hidden ml-10 laptop:inline">
            {{ $t('order_edit.print_and_send') }}
          </span>
        </button>
      </div>
    </Portal>
    <Transition name="transition-fade-slow">
      <OrderForm v-if="order.loadingStatus === 'done'" ref="order-form" :existing-order="order.formData"
        :human-readable-id="order.humanReadableId" :modified-order="order.modifiedTime" :created-order="order.createdTime"
        :location="order.location" :tooltips="allTooltips" :order-saved="false" @update="changeOrder($event)"
        @updatePrint="updateOrderPrint($event)" />
    </Transition>
  </div>
</template>

<script>
import { FBModule, FBStorageRef } from '@/helpers/firebaseServices';
import {
  getTooltips, getUserOrder, getUserOrders, getUserDetails, ordersPath,
} from '@/helpers/firestoreRefs';
import { getUserProfilesPath } from '@/helpers/storageRefs';
import { orderToFormDataStructure } from '@/helpers/orderDataStructure';
import printWindow from '@/helpers/functions/printWindow';
import OrderForm from '@/components/order/OrderForm';
import IconPrint from '@/components/icon/IconPrint';
import IconRefresh from '@/components/icon/IconRefresh';
import UITooltipsModule from '@/store/modules/UI/UITooltips';
import userOrdersModule from '@/store/modules/user/userOrders';
import { after } from 'xstate/lib/actionTypes';


export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    update: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    IconPrint,
    IconRefresh,
    OrderForm,
  },
  data: () => ({
    savingOrder: false,
    orderSaved: false,
    userDetails: null,
    order: {
      loadingStatus: 'init',
      formData: null,
      location: null,
      newHumanReadableId: '',
      modifiedTime: null,
      createdTime: null,
    },
    lastHumanReadableId: '',
    UITooltips: {
      unsubscribe: null,
      replaceState: true,
    },
  }),
  created() {
    if (!this.$store.state?.UI?.tooltips) this.$store.registerModule(['UI', 'tooltips'], UITooltipsModule);
    if (!this.$store.state.user?.orders) this.$store.registerModule(['user', 'orders'], userOrdersModule);

    this.UITooltips.unsubscribe = getTooltips()
      .onSnapshot((snapshot) => {
        this.$store.dispatch('UI/tooltips/onTooltipsSnapshot', {
          snapshot,
          replaceState: this.UITooltips.replaceState,
        });

        // Don't replace the cache on subsequent snapshots
        this.UITooltips.replaceState = false;
      }, (error) => {
        console.error(error);
      });

    if (!this.id) {
      this.order.loadingStatus = 'error';
      return;
    }
    getUserDetails(this.$store.state.user.auth.id)
      .get()
      .then(async (userSnapshot) => {
        this.userDetails = {
          id: userSnapshot.id,
          ...userSnapshot.data(),
        };
      })
      .catch((error) => {
        console.error(error);
        this.order.loadingStatus = 'error';
      });

    const self = this;
    getUserOrders(this.userAuthId)
      .orderBy('humanReadableId', 'asc')
      .onSnapshot((snapshot) => {
        if (!snapshot.docs[0]) return;
        const orders = snapshot.docs;

        orders.sort((prevOrder, nextOrder) => {
          const prevOrderNumber = parseInt(prevOrder.data().humanReadableId.split('-')[1], 10);
          const nextOrderNumber = parseInt(nextOrder.data().humanReadableId.split('-')[1], 10);

          return prevOrderNumber - nextOrderNumber;
        });

        // Get orders humanReadableId with digital ending
        const filteredOrders = orders.filter((order) => {
          const idParts = order.data().humanReadableId.split('-');
          const idPartsNum = idParts[idParts.length - 1];
          // const idPartsKey = idParts[0];
          if (Number.isInteger(+idPartsNum)) return order;
        });

        const lastOrderIndex = filteredOrders.length ? filteredOrders.length - 1 : orders.length - 1;
        const idParts = filteredOrders.length ? filteredOrders[lastOrderIndex].data().humanReadableId.split('-') : orders[lastOrderIndex].data().humanReadableId.split('-');
        let idPartsNum = idParts[idParts.length - 1];
        const idPartsKey = idParts[0];
        if (idPartsNum === 'F') idPartsNum = 0;

        self.lastHumanReadableId = `${idPartsKey}-${parseInt(idPartsNum, 10) + 1}`;
      }, (error) => {
        console.error(error);
      });

    getUserOrder(this.id)
      .get()
      .then(async (orderSnapshot) => {
        const order = {
          id: orderSnapshot.id,
          ...orderSnapshot.data(),
        };
        if (this.$route.params.duplicate) {
          order.orderDate = new Date().toLocaleDateString('de-CH', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
        }
        this.cleanOrder(order)
          .then((cleanedOrder) => {
            if (this.$route.params.duplicate) this.order.humanReadableId = this.humanReadableId || '';
            else this.order.humanReadableId = order.humanReadableId || '';
            this.order.location = order.location;

            const createdTime = new Date(order.createdTime.seconds * 1000);
            const modifiedTime = new Date(order.modifiedTime.seconds * 1000);
            if (typeof order.deliveryDate !== 'undefined' && typeof order.deliveryDate.seconds !== 'undefined') {
              const deliveryDate = new Date(order.deliveryDate.seconds * 1000)
              cleanedOrder.header.deliveryDate = deliveryDate //fixes #60109
            }

            if (typeof order.pickupDate !== 'undefined' && typeof order.pickupDate.seconds !== 'undefined') {
              const pickupDate = new Date(order.pickupDate.seconds * 1000)
              cleanedOrder.header.pickupDate = pickupDate //fixes #60109
            }

            this.order.formData = cleanedOrder;
            this.order.loadingStatus = 'done';
            this.order.createdTime = createdTime;
            this.order.modifiedTime = modifiedTime;

            // this.$store.dispatch('user/orders/onOrderSnapshot', cleanedOrder);
          })
          .catch((error) => {
            console.error(error);
            this.order.loadingStatus = 'error';
          });
      })
      .catch((error) => {
        console.error(error);
        this.order.loadingStatus = 'error';
      });
  },
  computed: {
    allTooltips() {
      if (!this.$store.state.UI?.tooltips) return null;
      return this.$store.state.UI.tooltips.all;
    },
    userAuthId() {
      return this.$store.state.user.auth.id;
    },
    humanReadableId() {
      return this.lastHumanReadableId;
    },
  },
  methods: {
    async printWindowOpen() {
      this.$refs['order-form'].submitPrintForm();
    },
    saveOrder() {
      this.$refs['order-form'].submitForm();
    },
    async cleanOrder(order) {
      const { formData: cleanedOrder, imageDownloads } = orderToFormDataStructure(order, true);
      try {
        await Promise.all(imageDownloads);
        console.log(cleanedOrder);
        return Promise.resolve(cleanedOrder);
      } catch (error) {
        console.error(error);
        return Promise.reject();
      }
    },

    changeOrder(order) {
      if (this.update) {
        this.updateOrder(order);
      } else {
        this.addOrder(order);
      }

      this.orderSaved = true;
    },

    async updateOrder(order) {
      this.savingOrder = true;

      // Uploading pictures
      const imageUploads = [];

      try {
        order.metalComponents.forEach((metalComponent) => {
          if (metalComponent.imageFile) {
            const imageFileStoragePath = `${getUserProfilesPath(this.userAuthId)}/${metalComponent.imageFile.name}`;
            const imageUpload = FBStorageRef
              .child(imageFileStoragePath)
              .put(metalComponent.imageFile)
              .then(() => {
                // eslint-disable-next-line no-param-reassign
                metalComponent.imageStoragePath = imageFileStoragePath;
              });
            imageUploads.push(imageUpload);

            // eslint-disable-next-line no-param-reassign
            delete metalComponent.imageFile;
          }
        });

        await Promise.all(imageUploads);
      } catch (error) {
        // Error during image upload
        console.error(error);
        this.savingOrder = false;
        return;
      }

      const modifiedTime = new Date();
      await getUserOrder(this.id).update({
        ...order.header,
        metalComponents: order.metalComponents,
        ...order.moreOptions,
        modifiedTime,
        companyName: this.userDetails.companyName,
      });
      this.savingOrder = false;
      this.order.modifiedTime = modifiedTime;
      //TODO: please review below line, it was added to fix #58919 but not sure it is the best solution
      this.$refs['order-form'].modal.isShowing = false;
      console.log('# Update order', order);
    },

    async updateOrderPrint(order) {
      this.savingOrder = true;

      // Uploading pictures
      const imageUploads = [];

      try {
        order.metalComponents.forEach((metalComponent) => {
          if (metalComponent.imageFile) {
            const imageFileStoragePath = `${getUserProfilesPath(this.userAuthId)}/${metalComponent.imageFile.name}`;
            const imageUpload = FBStorageRef
              .child(imageFileStoragePath)
              .put(metalComponent.imageFile)
              .then(() => {
                // eslint-disable-next-line no-param-reassign
                metalComponent.imageStoragePath = imageFileStoragePath;
              });
            imageUploads.push(imageUpload);

            // eslint-disable-next-line no-param-reassign
            delete metalComponent.imageFile;
          }
        });

        await Promise.all(imageUploads);
      } catch (error) {
        // Error during image upload
        console.error(error);
        this.savingOrder = false;
        return;
      }

      await getUserOrder(this.id).update({
        ...order.header,
        metalComponents: order.metalComponents,
        status: 3,
        ...order.moreOptions,
        modifiedTime: new Date(),
      });
      console.log('# in updateOrderPrint', order);
      this.order.modifiedTime = new Date();
      setTimeout(printWindow, 500);

      window.onafterprint = this.afterPrint;
    },
    afterPrint() {
      if (this.$route.name !== 'Orders') {
        this.$store.dispatch('user/orders/resetState');
        this.$router.push({
          name: 'Orders',
          params: {
            locale: this.$i18n.locale,
          },
        });
      }
      return '';
    },
    async addOrder(order) {
      this.savingOrder = true;

      // Uploading pictures
      const imageUploads = [];
      const imageUploadPaths = {};
      try {
        order.metalComponents.forEach((metalComponent, componentIndex) => {
          if (metalComponent.imageFile) {
            const imageFileStoragePath = `${getUserProfilesPath(this.userAuthId)}/${metalComponent.imageFile.name}`;
            const imageUpload = FBStorageRef
              .child(imageFileStoragePath)
              .put(metalComponent.imageFile)
              .then(() => {
                imageUploadPaths[componentIndex] = imageFileStoragePath;
              });
            imageUploads.push(imageUpload);
          }
        });

        await Promise.all(imageUploads);
      } catch (error) {
        // Error during image upload
        console.error(error);
        this.savingOrder = false;
        return;
      }

      const { header: headerOptions, metalComponents, moreOptions } = order;
      const nowTimestamp = FBModule.firestore.Timestamp.fromDate(new Date());

      const cleanOrderFields = {
        // Header
        ...headerOptions.billingAddress && { billingAddress: headerOptions.billingAddress },
        ...headerOptions.shippingAddress && { shippingAddress: headerOptions.shippingAddress },
        ...headerOptions.phoneNumberForQuestions && { phoneNumberForQuestions: headerOptions.phoneNumberForQuestions },
        ...headerOptions.offerNumber && { offerNumber: headerOptions.offerNumber },
        ...headerOptions.deliveryDate && { deliveryDate: headerOptions.deliveryDate },
        ...headerOptions.pickupDate && { deliveryDate: headerOptions.pickupDate },

        orderDate: nowTimestamp,
        ...headerOptions.orderNumber && { orderNumber: headerOptions.orderNumber },
        ...headerOptions.commission && { commission: headerOptions.commission },
        ...headerOptions.deliverySlipNumber && { deliverySlipNumber: headerOptions.deliverySlipNumber },
      };

      // Metal components
      const cleanMetalComponents = [];
      metalComponents.forEach((metalComponent, componentIndex) => {
        // Metal component - Profile types
        const profilesWithDimensions = [];
        metalComponent.profilesWithDimensions.forEach((profileWithDimensions) => {
          if (profileWithDimensions.type || profileWithDimensions.dimensions) {
            profilesWithDimensions.push({ ...profileWithDimensions });
          }
        });

        // Metal component
        const cleanMetalComponent = {
          ...metalComponent.position && { position: metalComponent.position },
          ...metalComponent.count && { count: metalComponent.count },
          ...metalComponent.materialDesignation && { materialDesignation: metalComponent.materialDesignation },
          ...imageUploadPaths[componentIndex] && { imageStoragePath: imageUploadPaths[componentIndex] },
          ...profilesWithDimensions.length && { profilesWithDimensions },
          ...metalComponent.length && { length: metalComponent.length },
          ...metalComponent.width && { width: metalComponent.width },
          ...metalComponent.depth && { depth: metalComponent.depth },
        };

        if (Object.keys(cleanMetalComponent).length) {
          cleanMetalComponents.push(cleanMetalComponent);
        }
      });

      if (cleanMetalComponents.length) cleanOrderFields.metalComponents = cleanMetalComponents;

      // More options
      // More options - Treatment
      if (Object.keys(moreOptions.treatment).length) {
        const { treatment: treatmentOptions } = moreOptions;

        cleanOrderFields.treatment = {
          ...treatmentOptions.hotDipGalvanizingISO1461 && { hotDipGalvanizingISO1461: true },
          ...treatmentOptions.DASt022TrustZone?.length && { DASt022TrustZone: treatmentOptions.DASt022TrustZone },
          ...treatmentOptions.onlyPickleZincAndBackBlack && { onlyPickleZincAndBackBlack: true },
          ...treatmentOptions.duroplexCoating && { duroplexCoating: true },
          ...treatmentOptions.thermoplexCoating && { thermoplexCoating: true },
          ...treatmentOptions.duroThermoShade && { duroThermoShade: treatmentOptions.duroThermoShade },
          ...treatmentOptions.SPlusZ && { SPlusZ: true },
          ...treatmentOptions.ferrostyleCoatingN && { ferrostyleCoatingN: true },
          ...treatmentOptions.ferrostyleCoatingP && { ferrostyleCoatingP: true },
          ...treatmentOptions.ferrostyleShade && { ferrostyleShade: treatmentOptions.ferrostyleShade },
          ...treatmentOptions.indoorApplication && { indoorApplication: true },
          ...treatmentOptions.outdoorApplication && { outdoorApplication: true },
          ...treatmentOptions.galvanicZincPlating && { galvanicZincPlating: true },
          ...treatmentOptions.brightChromePlating && { brightChromePlating: true },
          ...treatmentOptions.blueChromating && { blueChromating: true },
          ...treatmentOptions.EN1090 && { EN1090: true },
          // eslint-disable-next-line max-len
          ...treatmentOptions.corrosivityCategory?.length && { corrosivityCategory: treatmentOptions.corrosivityCategory },
          ...treatmentOptions.preparationDegree?.length && { preparationDegree: treatmentOptions.preparationDegree },
          ...treatmentOptions.preparationDegreeTest?.length && { preparationDegreeTest: treatmentOptions.preparationDegreeTest },
        };

        if (!Object.keys(cleanOrderFields.treatment).length) delete cleanOrderFields.treatment;
      }

      // More options - Special
      if (Object.keys(moreOptions.special).length) {
        const { special: specialOptions } = moreOptions;

        cleanOrderFields.special = {
          ...specialOptions.cleanThread && { cleanThread: true },
          ...specialOptions.cleanThreadCount && { cleanThreadCount: specialOptions.cleanThreadCount },
          ...specialOptions.QS && { QS: true },
          ...specialOptions.measurementProtocol && { measurementProtocol: true },
          ...specialOptions.measurementProtocolAdvanced && { measurementProtocolAdvanced: true },
          ...specialOptions.drilledInternalHoles && { drilledInternalHoles: true },
          ...specialOptions.closeHoles && { closeHoles: true },
          ...specialOptions.noAdditionallyDrilledHoles && { noAdditionallyDrilledHoles: true },
          ...specialOptions.removeZincOnly && { removeZincOnly: true },
          ...specialOptions.burnOffPaintOnly && { burnOffPaintOnly: true },
          ...specialOptions.removePaintAndZinc && { removePaintAndZinc: true },
          ...specialOptions.euroPalletsCount && { euroPalletsCount: specialOptions.euroPalletsCount },
          ...specialOptions.palletFramesCount && { palletFramesCount: specialOptions.palletFramesCount },
          ...specialOptions.halfPalletFramesCount && { halfPalletFramesCount: specialOptions.halfPalletFramesCount },
          ...specialOptions.disposablePalletsCount && { disposablePalletsCount: specialOptions.disposablePalletsCount },
          ...specialOptions.otherDescription && { otherDescription: specialOptions.otherDescription },
        };

        if (!Object.keys(cleanOrderFields.special).length) delete cleanOrderFields.special;
      }

      // More options - Transport delivery
      if (Object.keys(moreOptions.transportDelivery).length) {
        const { transportDelivery: transportDeliveryOptions } = moreOptions;

        cleanOrderFields.transportDelivery = {
          ...transportDeliveryOptions.byGalvaswissLorry && { byGalvaswissLorry: true },
          ...transportDeliveryOptions.pickUpByHimself && { pickUpByHimself: true },
        };

        if (!Object.keys(cleanOrderFields.transportDelivery).length) delete cleanOrderFields.transportDelivery;
      }

      // More options - Transport return delivery
      if (Object.keys(moreOptions.transportReturnDelivery).length) {
        const { transportReturnDelivery: transportReturnDeliveryOptions } = moreOptions;

        cleanOrderFields.transportReturnDelivery = {
          ...transportReturnDeliveryOptions.byGalvaswissLorry && { byGalvaswissLorry: true },
          ...transportReturnDeliveryOptions.pickUpByHimself && { pickUpByHimself: true },
        };

        if (!Object.keys(cleanOrderFields.transportReturnDelivery).length) {
          delete cleanOrderFields.transportReturnDelivery;
        }
      }

      /*
      # Doc spec
      humanReadableId: String (Ex.: metallbau HEIMSCH GmbH: HEM-0001)
      status: Number (0: active status, 1: marked as processed by ERP)
      location: Document fields (Document ref)
     */
      const orderDoc = {
        // Doc meta
        createdTime: new Date(),
        modifiedTime: new Date(),
        createdBy: this.userAuthId,
        humanReadableId: this.humanReadableId || 'GAS-F',
        status: 0,
        location: {
          ...this.orderLocation,
        },
        ...cleanOrderFields,
      };

      console.log(orderDoc);
      ordersPath.add(orderDoc)
        .then((docRef) => {
          console.log(docRef);
          console.log('Document written with ID: ', docRef.id);
          this.savingOrder = false;
          this.orderSaved = true;

          this.$store.dispatch('user/orders/onOrderSnapshot', {
            id: docRef.id,
            ...orderDoc,
          });
        })
        .catch((error) => {
          this.savingOrder = false;
          console.error(error);
        });
    },
  },
  beforeDestroy() {
    if (this.UITooltips.unsubscribe !== null) this.UITooltips.unsubscribe();
  },
};
</script>

<style scoped>
.icon-print .primary {
  @apply fill-gray-400;
}
</style>
